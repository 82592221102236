import {
  TextInput,
  SelectInput,
  AutocompleteArrayInput
} from 'react-admin';

export const getMediaPlatformSettingsFilters = ({ accountNames, accountIds }) => [
  <TextInput
    source="q"
    label="ID, Internal Account ID, Account ID, Affiliate ID, Domain Path, Facebook Pixel ID"
    alwaysOn
    sx={{ width: '700px' }}
  />,
  <TextInput
    source="fb_pixel_id"
    label="Facebook Pixel ID"
    className="custom-input-width"
  />,
  <TextInput
    source="fb_api_key"
    label="Facebook API key"
    className="custom-input-width"
  />,
  <TextInput
    source="event_source_url"
    label="Event source URL"
    className="custom-input-width"
  />,
  <AutocompleteArrayInput
    source="account_ids"
    label="Account Ids"
    choices={accountIds}
    optionText="name"
    optionValue="id"
    className="input-width-lg"
  />,
  <AutocompleteArrayInput
    source="account_names"
    label="Account Names"
    choices={accountNames}
    optionText="name"
    optionValue="id"
    className="input-width-lg"
  />,
  <SelectInput
    source="settings.enable_pulling_data_tune"
    label="Sending Tune Data Enabled"
    choices={[
      { id: true, name: 'Yes' },
      { id: false, name: 'No' }
    ]}
    className="input-width-md"
  />,
  <SelectInput
    source="settings.enable_pulling_data_trackcb"
    label="Sending TrackCB Data Enabled"
    choices={[
      { id: true, name: 'Yes' },
      { id: false, name: 'No' }
    ]}
    className="input-width-md"
  />,
  <SelectInput
    source="settings.enable_pulling_data_clicks"
    label="Sending Clicks Data Enabled"
    choices={[
      { id: true, name: 'Yes' },
      { id: false, name: 'No' }
    ]}
    className="input-width-md"
  />
];
