import { Box } from '@mui/material';
import { CustomBooleanInput } from '../../../../helperComponents/CustomBooleanInput';

export const GoogleSpecificFields = () => (
  <Box display="flex" flexDirection="row" alignItems="center" gap={2} flexWrap="wrap">
    <CustomBooleanInput
      source="add_to_search_term"
      label="Add to Google Search Terms Report"
    />
    <CustomBooleanInput
      source="add_to_youtube_report"
      label="Add to YouTube Report"
    />
  </Box>
);
