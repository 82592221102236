import { useState } from 'react';
import {
  SelectInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  ReferenceArrayInput,
  useRecordContext
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { DateRangePicker } from 'react-date-range';
import { CustomPaper } from '../../../../helperComponents/CustomPaper';
import { useAffiliates } from '../../../../../hooks/useAffiliates';
import {
  AFFILIATE_OPTIONS_TRACKCB_LIST,
  AFFILIATE_OPTIONS_TRACKCB,
  START_DATE_OPTIONS,
  START_DATE_TYPES,
  PROCESS_STATUSES,
  DATA_SOURCES
} from '../../../../../config/constants/apiConstants';
import { JsonBooleanInput } from '../../../../helperComponents/JsonBooleanInput';
import { TrackCBHelperText } from './TrackCBHelperText';
import { ConvertEventToggle } from './ConvertEventToggle';
import {
  initializeCustomDateRange,
  updateCustomDateRange,
} from '../../../../../utils/meta/mediaPlatformApiSettingsUtils';

export const ClicksTab = () => {
  const affiliates = useAffiliates();
  const record = useRecordContext();
  const { getValues, setValue } = useFormContext();
  const [hasHistoricalData, setHasHistoricalData] = useState(false);
  const [customDateRange, setCustomDateRange] = useState(() =>
    initializeCustomDateRange({
      getValues,
      record,
      dateRangeField: 'custom_date_range_clicks',
    })
  );

  return (
    <>
      <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
        <ReferenceArrayInput
          source="brand_ids_clicks"
          reference="active_entities/brands"
          sort={{ field: 'id', order: 'ASC' }}
        >
          <AutocompleteArrayInput
            style={{ minWidth: '600px' }}
            label="Brands"
            optionText={(choice) => `${choice.id} - ${choice.brand_name}`}
            helperText="Brand must be selected for Click events to be captured."
            debounce={1000}
          />
        </ReferenceArrayInput>
        <JsonBooleanInput 
          source="enable_pulling_data_clicks"
          jsonSource="settings"
          label="Enable Sending Clicks Data"
          defaultValue={true}
        />
      </div>
      <SelectInput
        source="affiliate_option_clicks"
        label="Affiliate Option"
        choices={AFFILIATE_OPTIONS_TRACKCB_LIST}
        className="custom-input-width"
      />
      <FormDataConsumer>
        {({ formData }) => (
          <AutocompleteArrayInput
            label="Affiliate IDs"
            source="affiliate_ids_clicks"
            choices={affiliates.map((affiliate) => ({
              id: affiliate.affiliate_id,
              name: `${affiliate.affiliate_id} - ${affiliate.affiliate}`,
            }))}
            PaperComponent={(props) => (
              <CustomPaper
                {...props}
                width="670px"
                elevation={8}
                margin="0 0 0 150px"
              />
            )}
            style={{ minWidth: '600px' }}
            disabled={formData.affiliate_option_clicks !== AFFILIATE_OPTIONS_TRACKCB.LIST}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => {
          const isAffiliateInputDisabled = formData.affiliate_option_clicks !== AFFILIATE_OPTIONS_TRACKCB.NONE &&
                                           formData.affiliate_option_clicks !== AFFILIATE_OPTIONS_TRACKCB.ALL;
          return (
            <AutocompleteArrayInput
              label="Exclude Affiliate IDs"
              source="excluded_affiliate_ids_clicks"
              choices={affiliates.map(affiliate => ({
                id: affiliate.affiliate_id,
                name: `${affiliate.affiliate_id} - ${affiliate.affiliate}`,
              }))}
              PaperComponent={props => (
                <CustomPaper
                  {...props}
                  width="670px"
                  elevation={8}
                  margin="0 0 0 150px"
                />
              )}
              style={{ minWidth: '600px', paddingBottom: '26px'}}
              disabled={isAffiliateInputDisabled}
              helperText={<span style={{ color: '#00000099' }}>Exclude Affiliate IDs is disabled. To enable, select All Data or Tune All Affiliates from Affiliate Option field.</span>}
            />
          );
        }}
      </FormDataConsumer>
      <div className="d-flex">
        <JsonBooleanInput 
          source="add_clicks_event_id"
          jsonSource="settings"
          label="Add Event ID (VID-TID-CustomerID)"
        />
        <JsonBooleanInput 
          source="add_clicks_external_id"
          jsonSource="settings"
          label="Add External ID (VID-TID-CustomerID)"
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-start', gap: '20px', marginTop: '20px' }}>
        <SelectInput
          source="start_date_clicks"
          label="Start Date for Click events"
          choices={START_DATE_OPTIONS}
          className="custom-input-width"
          helperText={<TrackCBHelperText
            fetchStatusSummaryData={record?.fetch_status_summary_clicks || []}
            recordSettings={record?.settings}
            hasHistoricalData={hasHistoricalData}
            dataSourceType={DATA_SOURCES.TRACKCB_CLICKS}
          />}
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.start_date_clicks === START_DATE_TYPES.CUSTOM && (
              <>
                <div
                  style={{
                    position: 'relative',
                    padding: '10px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <DateRangePicker
                    ranges={[{
                      startDate: customDateRange.startDate,
                      endDate: customDateRange.endDate,
                      key: 'selection',
                    }]}
                    inputRanges={[]}
                    onChange={(ranges) => setCustomDateRange(updateCustomDateRange({
                      ranges,
                      setValue,
                      dateRangeField: 'custom_date_range_clicks',
                    }))}
                    rangeColors={['#283593']}
                  />
                </div>
              </>
            )
          }
        </FormDataConsumer>
        <ConvertEventToggle
          settingsId={record?.id}
          source="convert_event_time_within_7_days_for_clicks"
          startDateField="start_date_clicks"
          status={PROCESS_STATUSES.FETCHED_HISTORICAL_CLICK_EVENTS}
          onHistoricalDataChange={setHasHistoricalData}
        />
      </div>
    </>
  );
};
