export const metaApiDataColumns = {
  id: {
    source: 'id',
    label: 'ID',
  },
  data_source: {
    source: 'data_source',
    label: 'Data Source',
  },
  settings: {
    source: 'settings',
    label: 'Settings',
  },
  settings_id: {
    source: 'settings_id',
    label: 'Settings ID',
  },
  pixel_id: {
    source: 'pixel_id',
    label: 'FB Pixel ID',
    tooltip: 'Tune affiliate_info1, media_platform_api_settings pixel id',
  },
  brand_id: {
    source: 'brand_id',
    label: 'Brand',
  },
  brand_ids: {
    source: 'brand_ids',
    label: 'Brand',
  },
  event_source_url: {
    source: 'event_source_url',
    label: 'Event Source URL',
  },
  domain_path: {
    source: 'domain_path',
    label: 'Domain Path',
  },
  order_id: {
    source: 'order_id',
    label: 'Order ID',
    tooltip: 'Tune advertiser_info, TrackCB order_id',
  },
  fb_event_id: {
    source: 'fb_event_id',
    label: 'Event ID',
    tooltip: 'Tune ad_id-advertiser_info, TrackCB vid-tid-customer_id-order_id',
  },
  internal_account_id: {
    source: 'internal_account_id',
    label: 'Internal Account ID',
  },
  account_id: {
    source: 'account_id',
    label: 'Account ID',
  },
  account_name: {
    source: 'account_name',
    label: 'Account Name',
  },
  campaign_id: {
    source: 'campaign_id',
    label: 'Campaign ID',
    tooltip: 'Tune affiliate_info3',
  },
  transaction_id: {
    source: 'transaction_id',
    label: 'Transaction ID',
    tooltip: 'Tune ad_id, TrackCB tid',
  },
  event_name: {
    source: 'event_name',
    label: 'Event Name',
    tooltip: 'Tune goal_name, TrackCB event_name',
  },
  value: {
    source: 'value',
    label: 'Value',
    tooltip: 'Tune payout * payout_percentage, TrackCB value * payout_percentage',
  },
  payout_percentage: {
    source: 'payout_percentage',
    label: 'Payout Percentage',
  },
  event_time: {
    source: 'event_time',
    label: 'Event Time',
    tooltip: 'Tune datetime, TrackCB event_time',
  },
  event_time_converted: {
    source: 'event_time_converted',
    label: 'Event Time Converted',
    tooltip: 'Converted historical data event_time',
  },
  api_response: {
    source: 'api_response',
    label: 'API Response',
  },
};
