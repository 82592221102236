import { httpClient } from '../../config';

export const isHistoricalDataAvailableToBeConverted = async ({ endpoint, requestData }) => {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(requestData)
    };
    const response = await httpClient(endpoint, options);
    return JSON.parse(response.body).isAvailable;
  } catch (error) {
    console.error('Error fetching historical data availability:', error);
    return null;
  }
};
