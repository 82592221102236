export const limitedArrayList = (columnData, limit) => {
  if (!columnData || columnData.length === 0) return null;

  const displayedItems = columnData.slice(0, limit);
  const remainingCount = columnData.length - limit;

  return (
    <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
      {displayedItems.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
      {remainingCount > 0 && <li>... ({remainingCount} more)</li>}
    </ul>
  );
};
