import { LabelWithTooltip } from '../../components/helperComponents/LabelWithTooltip';

export const getColumnLabel = (columnConfig) => {
  if (columnConfig.tooltip) {
    return LabelWithTooltip(columnConfig.label, columnConfig.tooltip);
  }
  return columnConfig.label;
};

export const getSettingsInfo = (settings) => {
  const options = [];

  if (settings?.enable_pulling_data_tune) options.push('Tune');
  if (settings?.enable_pulling_data_trackcb) options.push('TrackCB');
  if (settings?.enable_pulling_data_clicks) options.push('Clicks');

  if (options.length === 0) return '';

  return `${options.join(' & ')}`;
};
