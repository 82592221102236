import { useState, useEffect } from 'react';
import { useListContext } from 'react-admin';
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from 'react-date-range';
import { FormControl, InputLabel, OutlinedInput, Button, ClickAwayListener } from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {
  applyDateRange,
  formatDateRange,
  createCustomStaticRanges
} from '../../utils/dateUtils';

export const DateRangeFilter = ({ dateRange, firstRecordTime }) => {
  const { setFilters, filterValues } = useListContext();
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: dateRange?.startDate ? new Date(dateRange.startDate) : '',
      endDate: dateRange?.endDate ? new Date(dateRange.endDate) : new Date(),
      key: 'selection',
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    if (dateRange?.startDate || dateRange?.endDate) {
      setSelectedRange([
        {
          startDate: dateRange.startDate ? new Date(dateRange.startDate) : '',
          endDate: dateRange.endDate ? new Date(dateRange.endDate) : new Date(),
          key: 'selection',
        },
      ]);
    }
  }, [dateRange]);

  const customStaticRanges = [
    ...defaultStaticRanges,
    ...createStaticRanges(createCustomStaticRanges(firstRecordTime)),
  ];

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <FormControl variant="outlined" size="small" style={{ margin: '0 10px', width: '280px' }}>
        <InputLabel htmlFor="date-range">Date Range</InputLabel>
        <OutlinedInput
          id="date-range"
          value={`${formatDateRange(selectedRange[0].startDate)} - ${formatDateRange(selectedRange[0].endDate)}`}
          readOnly
          label="Date Range"
          onClick={() => setShowCalendar((prev) => !prev)}
          style={{ cursor: 'pointer' }}
        />
      </FormControl>
      {showCalendar && (
        <ClickAwayListener onClickAway={() => setShowCalendar(false)}>
          <div
            style={{
              position: 'absolute',
              zIndex: 1000,
              top: '80px',
              left: '-130px',
              transform: 'scale(1.2)',
              backgroundColor: '#f0f4f7',
              padding: '12px',
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <DateRangePicker
              ranges={selectedRange}
              onChange={(ranges) => setSelectedRange([ranges.selection])}
              inputRanges={[]}
              staticRanges={customStaticRanges}
              rangeColors={['#283593']}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => applyDateRange({ selectedRange, filterValues, setFilters, setShowCalendar })}
                style={{
                  backgroundColor: '#283593',
                  padding: '5px 10px',
                  fontSize: '0.70rem',
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};
