import { ArrayWithTooltip } from '../components/helperComponents/ArrayWithTooltip';
import { metaApiDataColumns } from '../config/constants/meta/metaApiDataTableConstants';

export const findBrandById = (id, brands) => {
  return brands?.find((brand) => brand.id === id) || null;
};

export const formatBrandName = (id, brands) => {
  const brand = findBrandById(id, brands);
  return brand ? `${id} - ${brand.brand_name}` : id;
};

export const formatBrandNames = (brandIds, brands) => {
  if (!Array.isArray(brandIds) || !Array.isArray(brands)) return [];
  return brandIds.map((id) => formatBrandName(id, brands)).filter(Boolean);
};

export const renderMultipleBrandsWithNames = (record, brands) => (
  <ArrayWithTooltip
    source="brand_ids"
    record={{ brand_ids: formatBrandNames(record.brand_ids, brands) }}
    maxDisplay={3}
  />
);

export const renderBrandWithName = (record, brands) => {
  const brandId = record[metaApiDataColumns.brand_id.source];
  return formatBrandName(brandId, brands);
};
