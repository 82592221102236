import { TrackCBSyncDescription } from './TrackCBSyncDescription';
import { TRACKCB_CONVERSION_API_HELPER_TEXT } from '../../../../../config/constants/meta/mediaPlatformApiSettingsConstants'
import { useBrands } from '../../../../../hooks/useBrands';
import {
  buildTrackCBSyncSections,
  builSyncStatusMessage
} from '../../../../../utils/meta/mediaPlatformApiSettingsUtils';

export const TrackCBHelperText = ({ fetchStatusSummaryData, recordSettings, hasHistoricalData, dataSourceType }) => {
  const { brands } = useBrands();
  const sections = buildTrackCBSyncSections(fetchStatusSummaryData?.brand_fetch_status || [], brands);
  const syncStatusMessage = builSyncStatusMessage({ fetchStatusSummaryData, recordSettings, dataSourceType });

  return (
    <>
      <span>{TRACKCB_CONVERSION_API_HELPER_TEXT}</span>
      {sections.map(({ title, data }) => (
        <TrackCBSyncDescription key={title} title={title} data={data} />
      ))}
      {hasHistoricalData && syncStatusMessage}
    </>
  );
};
