import { useState, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { JsonBooleanInput } from '../../../../helperComponents/JsonBooleanInput';
import { isHistoricalDataAvailableToBeConverted } from '../../../../../utils/loaders/metaApi';
import { START_DATE_TYPES } from '../../../../../config/constants/apiConstants';

export const ConvertEventToggle = ({ settingsId, source, startDateField, status, onHistoricalDataChange }) => {
  const [showConvertEventButton, setShowConvertEventButton] = useState(false);
  const startDate = useWatch({ name: startDateField });

  useEffect(() => {
    const checkButtonVisibility = async () => {
      const hasHistoricalData = settingsId && status
        ? await isHistoricalDataAvailableToBeConverted({
            endpoint: '/tables/meta_api_data/is-historical-data-available',
            requestData: {
              settingsId,
              status,
            },
          })
        : false;
      
      onHistoricalDataChange?.(hasHistoricalData);
      setShowConvertEventButton(startDate === START_DATE_TYPES.CUSTOM || hasHistoricalData);
    };

    checkButtonVisibility();
  }, [startDate, settingsId, status]);

  return showConvertEventButton ? (
    <JsonBooleanInput
      source={source}
      jsonSource="settings"
      label="Convert event time within last 7 days (off - Collect to Analytics only, on - Convert and send to FB)"
    />
  ) : null;
};
