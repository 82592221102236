import {
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  useTheme,
  SelectColumnsButton,
} from 'react-admin';
import { Button, Tooltip } from '@mui/material';
import { ReorderRounded } from '@mui/icons-material';
import { darkTheme, lightTheme } from '../../layout/themes';

export const ToolBarRowStyleToggle = ({
  label,
  showSelectColumnsButton = false,
  showExportButton = true
}) => {
  const [theme, setTheme] = useTheme();

  return (
    <TopToolbar>
      <Button
        onClick={() => {
          const value = !localStorage.getItem('RaStore.isRowStyleActive')
            ? '1'
            : '';
          localStorage.setItem('RaStore.isRowStyleActive', value);
          if (theme?.palette.mode === 'dark') {
            setTheme({ ...darkTheme, isRowStyleActive: value });
          } else {
            setTheme({ ...lightTheme, isRowStyleActive: value });
          }
        }}
      >
        <Tooltip title="Color every second row" placement="bottom">
          <ReorderRounded color="action" fontSize="small" />
        </Tooltip>
      </Button>
      {showSelectColumnsButton && <SelectColumnsButton />}
      <FilterButton />
      <CreateButton label={label} />
      {showExportButton && <ExportButton />}
    </TopToolbar>
  );
};
