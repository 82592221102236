import { useEffect, useState } from 'react';
import { fetchBrands } from '../utils/loaders/brandApi';

export const useBrands = () => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const loadBrands = async () => {
      try {
        const data = await fetchBrands();
        setBrands(data);
      } catch (error) {
        console.error('Error loading brands:', error);
      }
    };

    loadBrands();
  }, []);

  return { brands };
};
