import { GOOGLE_REPORT_TYPES } from '../../config/constants/reportConstants';

export const getGoogleReportUploadUrl = ({ googleReportType, apiUrl }) => {
  switch (googleReportType) {
    case GOOGLE_REPORT_TYPES.SEARCH_TERMS:
      return `${apiUrl}/analytics/upload_report/search-terms`;
    case GOOGLE_REPORT_TYPES.YOUTUBE_REPORT:
      return `${apiUrl}/analytics/upload_report/youtube_report`;
    default:
      return `${apiUrl}/analytics/upload_report`;
  }
};

export const getGoogleAccountOptions = ({
  googleReportType,
  existingGoogleAccounts,
  googleAccountsForSearchTerm,
  googleAccountsForYoutubeReport,
}) => {
  switch (googleReportType) {
    case GOOGLE_REPORT_TYPES.MEDIA_REPORT:
      return existingGoogleAccounts;
    case GOOGLE_REPORT_TYPES.SEARCH_TERMS:
      return googleAccountsForSearchTerm;
    case GOOGLE_REPORT_TYPES.YOUTUBE_REPORT:
      return googleAccountsForYoutubeReport;
    default:
      return [];
  }
};
