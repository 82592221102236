import { BooleanInput } from 'react-admin';

export const CustomBooleanInput = ({ source, label }) => (
  <BooleanInput
    source={source}
    label={label}
    sx={{
      whiteSpace: 'nowrap',
      label: {
        whiteSpace: 'nowrap',
      },
    }}
  />
);
