import { useEffect, useRef, useState } from 'react'
import { apiUrl, httpClient } from '../../../config'
import { platforms } from '../../../helpers/constants'
import { ReportTypeToggle } from '../components/ReportTypeToggle'
import { useTheme } from 'react-admin'
import { darkModeFieldsColor } from '../helperConstants'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import '../styles/FileInput.css'
import { CustomPaper } from '../../helperComponents/CustomPaper'
import { processMetaReport, resetTargetButton } from '../../../utils/reportUtils'
import { UploadMetaReport } from '../components/reportComponents/UploadMetaReport'
import { PLATFORMS, GOOGLE_REPORT_TYPES } from '../../../config/constants/reportConstants'
import { getGoogleReportUploadUrl, getGoogleAccountOptions } from '../../../utils/reports/uploadReportUtils'

export const UploadReportPage = () => {
   const [file, setFile] = useState('')
   const [platform, setPlatform] = useState('')
   const [theme] = useTheme()

   const [accountName, setAccountName] = useState('')
   const [accountId, setAccountId] = useState('')

   const [selectedAccountName, setSelectedAccountName] = useState('')
   const [existingGoogleAccounts, setExistingGoogleAccounts] = useState([])
   const [googleAccountsForSearchTerm, setGoogleAccountsForSearchTerm] = useState([])
   const [googleAccountsForYoutubeReport, setGoogleAccountsForYoutubeReport] = useState([])

   const [metaAccountId, setMetaAccountId] = useState('')
   const [existingMetaAccounts, setExistingMetaAccounts] = useState([])

   const [existingOutbrainData, setExistingOutbrainData] = useState([])
   const [selectedOutbrainId, setSelectedOutbrainId] = useState('')


   const [outbrainDate, setOutbrainDate] = useState(null)
   const [outbrainType, setOutbrainType] = useState('ad-data')

   const [bingType, setBingType] = useState('no-is-data')

   const [taboolaDate, setTabooleDate] = useState(null)
   const [taboolaType, setTaboolaType] = useState('ad-data')

   const [existingTaboolaData, setExistingTaboolaData] = useState([])
   const [selectedTaboolaId, setSelectedTaboolaId] = useState('')

   const [successMessage, setSuccessMessage] = useState(null)
   const [errorMessage, setErrorMessage] = useState(null)

   const [googleReportType, setGoogleReportType] = useState(GOOGLE_REPORT_TYPES.MEDIA_REPORT)

   const fileInputRef = useRef(null)
   const selectAccountRef = useRef(null)
   const selectMetaAccountRef = useRef(null)
   const outbrainDateInputRef = useRef(null)
   const taboolaDateInputRef = useRef(null)

   const isDarkMode = theme?.palette.mode === 'dark'

   useEffect(() => {
      const doRequest = async () => {
        try {
          const response = await httpClient(`${apiUrl}/analytics/upload_report`);
          const parsedResponse = JSON.parse(response.body);
  
          const {
            googleAccounts,
            searchTermGoogleAccounts,
            youtubeReportGoogleAccounts,
            metaAccounts,
            outbrainData,
            taboolaData,
          } = parsedResponse;
  
          setExistingGoogleAccounts(googleAccounts);
          setGoogleAccountsForSearchTerm(searchTermGoogleAccounts);
          setGoogleAccountsForYoutubeReport(youtubeReportGoogleAccounts);
          setExistingMetaAccounts(metaAccounts);
          setExistingOutbrainData(outbrainData);
          setExistingTaboolaData(taboolaData);
        } catch (err) {
          console.log(err);
        }
      };
  
      doRequest();
   }, []);

   useEffect(() => {
      setSelectedAccountName('');
    }, [googleReportType]);

   const handleMetaResponse = (response) => {
      const parsedResponse = JSON.parse(response.body);
      setSuccessMessage(parsedResponse.successMessage);
      setErrorMessage(parsedResponse.errorMessage);
    
      if (parsedResponse.uploadSuccess) {
         setPlatform('');
         setMetaAccountId('');
         setFile('');
         fileInputRef.current.value = '';
         selectMetaAccountRef.current.value = '';
      }
   };

   const onChange = setFunc => ({target}) => {
      setFunc(target.value)
   }

   const onFileInput = ({target}) => {
      const file = target.files[0]
      setFile(file)
   }

   const onSubmit = async event => {
      event.preventDefault()
      const target = event.target
      setErrorMessage(null)
      setSuccessMessage(null)

      if (platform === 'Outbrain') {
         if ((outbrainType !== 'ad-data' && !selectedOutbrainId) ||
           !outbrainDate ||
           !file?.name
         ) {
            alert('Please specify fields, and choose related file')
            return
         }

         const isUploadedBeforeInstance = await httpClient('/analytics/upload_report/isUploadedBefore', {
            method: 'POST',
            body: JSON.stringify({
               fileName: file.name,
            }),
         })

         const {isUploadedBefore} = JSON.parse(isUploadedBeforeInstance.body)

         const confirmationMessage = isUploadedBefore ?
            `This report has been already uploaded today
        Do you want to upload it again?`
            :
            `Chosen data are 
      Platform: ${platform}     
      Date: ${outbrainDate}
      Type: ${outbrainType}
      Filename: ${file.name}
      ${isSectionType ? 'Campaign name: ' : ''} ${isSectionType ?
               existingOutbrainData.find(el => el.id === +selectedOutbrainId)?.campaign_name : ''}
      Do you confirm?`

         target.textContent = 'In progress'
         target.setAttribute('disabled', 'true')
         const isSectionType = !!(outbrainType === 'section-data' && selectedOutbrainId)

         const formData = new FormData()
         formData.append('platform', platform)
         formData.append('outbrain-date', outbrainDate)
         formData.append('outbrain-type', outbrainType)
         formData.append('report', file)

         outbrainType === 'section-data' && formData.append('existing-account', selectedOutbrainId)

         let response = window.confirm(confirmationMessage) && await httpClient(`${apiUrl}/analytics/upload_report`, {
            method: 'POST',
            body: formData,
         })

         if (response) {
            response = JSON.parse(response.body)
            setSuccessMessage(response.successMessage)
            setErrorMessage(response.errorMessage)

            if (response.uploadSuccess) {
               setOutbrainDate(null)
               outbrainDateInputRef.current.value = null
               setFile('')
               fileInputRef.current.value = ''
            }
         }

         target.textContent = 'Submit'
         target.removeAttribute('disabled')

      } else if (platform === 'Google') {
         if ((!accountName && !selectedAccountName) || !file?.name) {
            alert('Please specify fields, and choose related file')
            return
         }

         const isUploadedBeforeInstance = await httpClient('/analytics/upload_report/isUploadedBefore', {
            method: 'POST',
            body: JSON.stringify({
               accountName: accountName || existingGoogleAccounts.find(acc => acc.id === +selectedAccountName).account_name,
               fileName: file.name,
               platform,
            }),
         })

         const {isUploadedBefore} = JSON.parse(isUploadedBeforeInstance.body)

         const confirmationMessage = isUploadedBefore ?
           `This report has been already uploaded today
        Do you want to upload it again?`
           :
           `Chosen data are 
      Platform: ${platform}     
      Account name: ${accountName || existingGoogleAccounts.find(acc => acc.id === +selectedAccountName).account_name}
      Filename: ${file.name}
      Do you confirm?`

         target.textContent = 'In progress'
         target.setAttribute('disabled', 'true')

         const formData = new FormData()
         formData.append('platform', platform)
         accountName ? formData.append('account-name', accountName) : formData.append('existing-account', selectedAccountName)
         accountId && formData.append('account-id', accountId)
         formData.append('report', file)

         let response = window.confirm(confirmationMessage) && await httpClient(getGoogleReportUploadUrl({ googleReportType, apiUrl }), {
            method: 'POST',
            body: formData,
         })

         if (response) {
            response = JSON.parse(response.body)
            setSuccessMessage(response.successMessage)
            setErrorMessage(response.errorMessage)

            if (response.uploadSuccess) {
               setAccountName('')
               setAccountId('')
               setSelectedAccountName('')
               setFile('')
               fileInputRef.current.value = ''
               selectAccountRef.current.value = ''
            }
         }

         target.textContent = 'Submit'
         target.removeAttribute('disabled')
      } else if (platform === 'Bing') {
         if (!file?.name) {
            alert('Please specify fields, and choose related file')
            return
         }

         const isUploadedBeforeInstance = await httpClient('/analytics/upload_report/isUploadedBefore', {
            method: 'POST',
            body: JSON.stringify({
               fileName: file.name,
            }),
         })

         const {isUploadedBefore} = JSON.parse(isUploadedBeforeInstance.body)

         const confirmationMessage = isUploadedBefore ?
            `This report has been already uploaded today
        Do you want to upload it again?`
            :
            `Chosen data are 
      Platform: ${platform}     
      Type: ${bingType}
      Filename: ${file.name}
      Do you confirm?`

         const formData = new FormData()
         formData.append('platform', platform)
         formData.append('bing-type', bingType)
         formData.append('report', file)

         let response = window.confirm(confirmationMessage) && await httpClient(`${apiUrl}/analytics/upload_report`, {
            method: 'POST',
            body: formData,
         })

         if (response) {
            response = JSON.parse(response.body)
            setSuccessMessage(response.successMessage)
            setErrorMessage(response.errorMessage)
         }

      } else if (platform === 'Taboola') {
         if ((taboolaType !== 'ad-data' && !selectedTaboolaId) ||
           !taboolaDate ||
           !file?.name
         ) {
            alert('Please specify fields, and choose related file')
            return
         }

         const isUploadedBeforeInstance = await httpClient('/analytics/upload_report/isUploadedBefore', {
            method: 'POST',
            body: JSON.stringify({
               fileName: file.name,
            }),
         })

         const {isUploadedBefore} = JSON.parse(isUploadedBeforeInstance.body)

         const confirmationMessage = isUploadedBefore ?
            `This report has been already uploaded today
        Do you want to upload it again?`
            :
            `Chosen data are 
      Platform: ${platform}     
      Date: ${taboolaDate}
      Type: ${taboolaType}
      Filename: ${file.name}
      ${isSitesType ? 'Campaign name: ' : ''} ${isSitesType ?
               existingTaboolaData.find(el => el.id === +selectedTaboolaId)?.campaign_name : ''}
      Do you confirm?`

         target.textContent = 'In progress'
         target.setAttribute('disabled', 'true')
         const isSitesType = !!(taboolaType === 'sites-data' && selectedTaboolaId)

         const formData = new FormData()
         formData.append('platform', platform)
         formData.append('taboola-date', taboolaDate)
         formData.append('taboola-type', taboolaType)
         formData.append('report', file)

         taboolaType === 'sites-data' && formData.append('existing-account', selectedTaboolaId)

         let response = window.confirm(confirmationMessage) && await httpClient(`${apiUrl}/analytics/upload_report`, {
            method: 'POST',
            body: formData,
         })

         if (response) {
            response = JSON.parse(response.body)
            setSuccessMessage(response.successMessage)
            setErrorMessage(response.errorMessage)

            if (response.uploadSuccess) {
               setTabooleDate(null)
               setFile('')
               fileInputRef.current.value = ''
               taboolaDateInputRef.current.value = ''
            }
         }

         target.textContent = 'Submit'
         target.removeAttribute('disabled')

      } else if (platform === PLATFORMS.META) {
         const response = await processMetaReport({ 
            metaAccountId,
            existingMetaAccounts,
            file, 
            target
         })
        
         response && handleMetaResponse(response);
         resetTargetButton(target);
      } else {
         alert('Please specify platform')
         return
      }
   }

   return (
     <>
        <div className="container">
           <div className="content">
              {successMessage ?
                <div className="alert alert-success mt-4">{successMessage}</div>
                :
                null
              }
              {errorMessage ?
                <div className="alert alert-danger mt-4">{errorMessage}</div>
                :
                null
              }
              <form className="shadow p-3 mt-5 rounded border">
                 <p className="h5 mb-3">Upload report</p>
                 <div className="mb-3 row">
                    <div className="row">
                       <div className="col-4 mb-2">
                          <select name="platform"
                                  className="form-select"
                                  style={isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'}: {}}
                                  defaultValue=""
                                  value={platform}
                                  onChange={onChange(setPlatform)}
                                  required={true}
                          >
                             <option value="">Select platform</option>
                             {platforms.map(platform => <option value={platform.value}>{platform.name}</option>)}
                          </select>
                       </div>
                       {!platform &&
                         <div className="row">
                            <div className="col-6">
                               <label htmlFor="existing-account" className="form-label">Choose existing account</label>
                               <select name="existing-account"
                                       className="form-select"
                                       defaultValue=""
                                       disabled={true}
                                       style={isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'}: {}}
                               >
                                  <option value="">Need to select platform first</option>
                               </select>
                            </div>
                         </div>
                       }
                       {platform === PLATFORMS.GOOGLE &&
                         <div className="d-flex justify-content-between">
                           <div style={{ flex: '0 1 50%' }}>
                               <label htmlFor="existing-account" className="form-label">Choose existing account</label>
                               <Autocomplete
                                 id="google-accounts"
                                 options={getGoogleAccountOptions({
                                    googleReportType,
                                    existingGoogleAccounts,
                                    googleAccountsForSearchTerm,
                                    googleAccountsForYoutubeReport,
                                 })}
                                 getOptionLabel={(option) => `${option.account_name} – ${option.id}`}
                                 style={{ width: 380 }}
                                 disabled={!!(accountName || accountId)}
                                 value={existingGoogleAccounts.find(acc => acc.id === selectedAccountName) || null}
                                 ref={selectAccountRef}
                                 onChange={(event, newValue) => {
                                    setSelectedAccountName(newValue?.id || '');
                                 }}
                                 renderInput={(params) => 
                                    <TextField 
                                       {...params} 
                                       name="existing-account"
                                       variant="outlined" 
                                       style={{
                                          ...isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'} : {},
                                          marginTop: 0,
                                      }}
                                    />
                                 }
                              />
                            </div>
                            <div style={{ flex: '0 1 30%' }}>
                               <label htmlFor="report-type" className="form-label">Choose Report Type</label>
                               <select name="report-type"
                                       className="form-select"
                                       value={googleReportType}
                                       onChange={onChange(setGoogleReportType)}
                                       style={isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'}: {}}
                               >
                                  <option value={GOOGLE_REPORT_TYPES.MEDIA_REPORT}>Media Report</option>
                                  <option value={GOOGLE_REPORT_TYPES.SEARCH_TERMS}>Search Terms</option>
                                  <option value={GOOGLE_REPORT_TYPES.YOUTUBE_REPORT}>YouTube Report</option>
                               </select>
                            </div>
                         </div>
                       }
                       {platform === PLATFORMS.META && (
                          <UploadMetaReport
                            existingMetaAccounts={existingMetaAccounts}
                            metaAccountId={metaAccountId}
                            setMetaAccountId={setMetaAccountId}
                            selectMetaAccountRef={selectMetaAccountRef}
                            isDarkMode={isDarkMode}
                          />
                        )}
                    </div>
                    {platform === PLATFORMS.OUTBRAIN &&
                      <div className="row">
                         <div className="col-3">
                            <label htmlFor="outbrain-date" className="form-label">Select date</label>
                            <input type="date"
                                   ref={outbrainDateInputRef}
                                   className="form-control"
                                   name="outbrain-date"
                                   onChange={onChange(setOutbrainDate)}
                                   required={true}
                                   style={isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'}: {}}
                            />
                         </div>
                         <div className="col-5">
                            <ReportTypeToggle id="outbrain-type"
                                              onChange={onChange(setOutbrainType)}
                                              type={outbrainType}
                                              firstChoice={{
                                                 value: 'ad-data',
                                                 text: 'Ad Data',
                                              }}
                                              secondChoice={{
                                                 value: 'section-data',
                                                 text: 'Section Data',
                                              }}
                                              isDarkMode={isDarkMode}
                            />
                         </div>
                         <div className="col-4">
                            {outbrainType === 'section-data' &&
                              <>
                                 <label htmlFor="outbrain-campaign" className="form-label">Existing Campaigns</label>
                                 <Autocomplete
                                    id="outbrain-campaigns"
                                    options={existingOutbrainData}
                                    getOptionLabel={(option) => `${option.campaign_name} – ${option.internal_account_id}`}
                                    value={existingOutbrainData.find(obData => obData.id === selectedOutbrainId)}
                                    onChange={(event, newValue) => {
                                       setSelectedOutbrainId(newValue?.id || '');
                                    }}
                                    PaperComponent={(props) => <CustomPaper {...props} width="500px" elevation={8} />}
                                    renderInput={(params) => 
                                       <TextField 
                                          {...params} 
                                          name="outbrain-campaign"
                                          variant="outlined" 
                                          style={{
                                             ...isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'} : {},
                                             marginTop: 0,
                                          }}
                                       />
                                    }
                                 />
                              </>
                            }
                         </div>
                      </div>
                    }
                    {platform === PLATFORMS.BING &&
                      <div className="row">
                         <div className="col-5">
                            <ReportTypeToggle id="bing-type"
                                              type={bingType}
                                              onChange={onChange(setBingType)}
                                              labelText="Type"
                                              firstChoice={{
                                                 value: 'no-is-data',
                                                 text: 'No Impression Share data',
                                              }}
                                              isDarkMode={isDarkMode}
                            />
                         </div>
                      </div>
                    }
                    {platform === PLATFORMS.TABOOLA &&
                      <div className="row">
                         <div className="col-3">
                            <label htmlFor="taboola-date" className="form-label">Select date</label>
                            <input type="date"
                                   ref={taboolaDateInputRef}
                                   className="form-control"
                                   name="taboola-date"
                                   onChange={onChange(setTabooleDate)}
                                   required={true}
                                   style={isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'}: {}}
                            />
                         </div>
                         <div className="col-4">
                            <ReportTypeToggle id="taboola-type"
                                              onChange={onChange(setTaboolaType)}
                                              type={taboolaType}
                                              firstChoice={{
                                                 value: 'ad-data',
                                                 text: 'Ad Data',
                                              }}
                                              secondChoice={{
                                                 value: 'sites-data',
                                                 text: 'Sites Data',
                                              }}
                                              isDarkMode={isDarkMode}
                            />
                         </div>
                         <div className="col-5">
                            {taboolaType === 'sites-data' &&
                              <>
                                 <label htmlFor="taboola-campaign" className="form-label">Existing Campaigns</label>
                                 <Autocomplete
                                    id="taboola-campaigns"
                                    options={existingTaboolaData}
                                    getOptionLabel={(option) => `${option.campaign_name} – ${option.internal_account_id}`}
                                    style={{ width: 276 }}
                                    value={existingTaboolaData.find(tbData => tbData.id === selectedTaboolaId)}
                                    onChange={(event, newValue) => {
                                       setSelectedTaboolaId(newValue?.id || '');
                                    }}
                                    PaperComponent={(props) => <CustomPaper {...props} width="650px" elevation={8} />}
                                    renderInput={(params) => 
                                       <TextField 
                                          {...params} 
                                          name="taboola-campaign"
                                          variant="outlined" 
                                          required={true}
                                          style={{
                                             ...isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'} : {},
                                             marginTop: 0,
                                          }}
                                       />
                                    }
                                 />
                              </>
                            }
                         </div>
                      </div>
                    }
                 </div>
                 <div className="mb-3">
                    <label htmlFor="report" className="form-label">Upload report</label>
                    <input type="file"
                           accept=".csv"
                           name="report"
                           className={`form-control ${isDarkMode ? 'inputFile' : ''}`}
                           onChange={onFileInput}
                           required={true}
                           ref={fileInputRef}
                           style={isDarkMode ? {
                              backgroundColor: darkModeFieldsColor,
                              color: 'white',
                              border: 'none',
                           }: {}}
                    />
                 </div>
                 <button className="btn btn-primary"
                         type="button"
                         onClick={onSubmit}
                         disabled={
                           !platform ||
                           (platform === PLATFORMS.GOOGLE && !selectedAccountName) ||
                           (platform === PLATFORMS.META && !metaAccountId) ||
                           (platform === PLATFORMS.OUTBRAIN && !outbrainDate) ||
                           (outbrainType === 'section-data' && !selectedOutbrainId) ||
                           (platform === PLATFORMS.TABOOLA && !taboolaDate) ||
                           (taboolaType === 'sites-data' && !selectedTaboolaId)
                         }
                 >Submit
                 </button>
              </form>
           </div>
        </div>
     </>
   )
}