import { makePostRequest } from './loaders';
import { META_CSV_GENERATION_STATUSES } from '../config/constants';

export const generateReport = async ({ requestBody, endpoint, setStatus }) => {
  try {
    const response = await makePostRequest(endpoint, requestBody);
    const result = JSON.parse(response.body);
    setStatus(result.status);
    return result;
  } catch (error) {
    console.error('Error generating report:', error);
  }
};

export const constructDownloadUrl = (endpoint, params) => {
  const query = new URLSearchParams(params).toString();
  return `${endpoint}?${query}`;
};

export const initiateDownload = ({ endpoint, params, setStatus, notFoundStatus }) => {
  const downloadUrl = constructDownloadUrl(endpoint, params);
  window.location.href = downloadUrl;
  setStatus(notFoundStatus);
};

export const checkDownloadStatus = async ({ setStatus, endpoint, notFoundStatus }) => {
  try {
    const response = await makePostRequest(endpoint);
    const { status } = JSON.parse(response.body);
    setStatus(status);
  } catch (error) {
    console.error('Error checking download status:', error);
    setStatus(notFoundStatus);
  }
};

export const getMetaApiExportButtonLabel = (status) => {
  switch (status) {
    case META_CSV_GENERATION_STATUSES.COMPLETED:
      return 'Download';
    case META_CSV_GENERATION_STATUSES.IN_PROGRESS:
      return 'In Progress';
    default:
      return 'Prepare Export';
  }
};
