export const TrackCBSyncDescription = ({ title, data }) => (
  data.length > 0 && (
    <div style={{ color: '#00000099', marginTop: '10px' }}>
      {title}:
      <ul>
        {data.map((syncStatus) => (
          <li key={syncStatus}>{syncStatus}</li>
        ))}
      </ul>
    </div>
  )
);
