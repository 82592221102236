import moment from 'moment';
import { TRACKCB_CONVERSION_API_SYNC_TYPES } from '../../config/constants/meta/mediaPlatformApiSettingsConstants';
import { DATA_SOURCES } from '../../config/constants/apiConstants';
import { START_DATE_TYPES } from '../../config/constants/apiConstants';
import { formatBrandName } from '../brandUtils';
import{ getDate } from '../timeUtils';

export const buildTrackCBSyncSections = (brandFetchStatusData, brands) => [
  {
    title: 'Last Sync',
    data: getSyncStatusData({
      brandFetchStatusData,
      brands,
      type: TRACKCB_CONVERSION_API_SYNC_TYPES.LAST_SYNC,
    }),
  },
  {
    title: '"Last 7 Days" Sync',
    data: getSyncStatusData({
      brandFetchStatusData,
      brands,
      type: TRACKCB_CONVERSION_API_SYNC_TYPES.SEVEN_DAYS,
    }),
  },
  {
    title: 'Historical Data Sync',
    data: getSyncStatusData({
      brandFetchStatusData,
      brands,
      type: TRACKCB_CONVERSION_API_SYNC_TYPES.CUSTOM,
    }),
  },
];

const getSyncStatusData = ({ brandFetchStatusData, brands, type }) => {
  let filteredData;

  switch (type) {
    case TRACKCB_CONVERSION_API_SYNC_TYPES.SEVEN_DAYS:
      filteredData = brandFetchStatusData.filter(item => item.seven_days_data_update);
      break;

    case TRACKCB_CONVERSION_API_SYNC_TYPES.CUSTOM:
      filteredData = brandFetchStatusData.filter(item => item.custom_period_data_update);
      break;

    default:
      filteredData = brandFetchStatusData;
      break;
  }

  return filteredData?.map(item => formatSyncEntry({ item, brands, type }));
};

const formatSyncEntry = ({ item, brands, type }) => {
  const brandNameWithId = formatBrandName(item.brand_id, brands);

  switch (type) {
    case TRACKCB_CONVERSION_API_SYNC_TYPES.LAST_SYNC: {
      const time = item.last_update_time ? `Last Update: ${item.last_update_time}` : 'No updates yet';
      const hasErrors = item.errors?.[START_DATE_TYPES.LAST_RECORD_TIME] === true;
      const status = hasErrors ? 'Error' : 'Success';

      return `${brandNameWithId} (${time}, Status: ${status})`;
    }

    case TRACKCB_CONVERSION_API_SYNC_TYPES.SEVEN_DAYS: {
      const { period, zero_events_count } = item.seven_days_data_update;
      const status = zero_events_count ? 'No events found' : 'Events found and processed';
      return `${brandNameWithId} (Period: ${period}, Status: ${status})`;
    }

    case TRACKCB_CONVERSION_API_SYNC_TYPES.CUSTOM: {
      const { period, zero_events_count } = item.custom_period_data_update;
      const status = zero_events_count ? 'No events found' : 'Events found and processed';
      return `${brandNameWithId} (Period: ${period}, Status: ${status})`;
    }

    default:
      return '';
  }
};

export const initializeCustomDateRange = ({ getValues, record, dateRangeField }) => {
  const savedRange = getValues(`settings.${dateRangeField}`) || record?.settings?.[dateRangeField];

  return {
    startDate: getDate(savedRange?.start_date),
    endDate: getDate(savedRange?.end_date),
  };
};

export const updateCustomDateRange = ({ ranges, setValue, dateRangeField }) => {
  const updatedRange = {
    start_date: moment(ranges.selection.startDate).format('YYYY-MM-DD'),
    end_date: moment(ranges.selection.endDate).format('YYYY-MM-DD'),
  };

  setValue(`settings.${dateRangeField}`, updatedRange, { shouldDirty: true });

  return {
    startDate: getDate(updatedRange.start_date),
    endDate: getDate(updatedRange.end_date),
  };
};

export const builSyncStatusMessage = ({ fetchStatusSummaryData, recordSettings, dataSourceType }) => {
  const brandFetchStatus = fetchStatusSummaryData?.brand_fetch_status || [];
  const dateRangeField = getDateRangeFieldFromSettings(dataSourceType);

  if (!dateRangeField) return '';

  const customDateRange = recordSettings?.[dateRangeField];

  return evaluateHistoricalDataSyncStatus({ brandFetchStatus, customDateRange });
};

const getDateRangeFieldFromSettings = (dataSourceType) => {
  switch (dataSourceType) {
    case DATA_SOURCES.TRACKCB:
      return 'custom_date_range';

    case DATA_SOURCES.TRACKCB_CLICKS:
      return 'custom_date_range_clicks';

    default:
      return null;
  }
};

const evaluateHistoricalDataSyncStatus = ({ brandFetchStatus, customDateRange }) => {
  if (!validateDateRange(customDateRange)) {
    return '';
  }

  const { customStartMoment, customEndMoment } = parseDateRange(customDateRange);

  if (!customStartMoment || !customEndMoment) {
    return '';
  }

  const allBrandsReady = checkAllBrandsSynced({ brandFetchStatus, customStartMoment, customEndMoment });

  return allBrandsReady ? 'Collection has been completed and ready to be synced' : '';
};

const validateDateRange = (customDateRange) => {
  return customDateRange && customDateRange.start_date && customDateRange.end_date;
};

const parseDateRange = (customDateRange) => {
  const customStartMoment = moment(customDateRange.start_date, 'YYYY-MM-DD', true);
  const customEndMoment = moment(customDateRange.end_date, 'YYYY-MM-DD', true);

  return {
    customStartMoment: customStartMoment.isValid() ? customStartMoment : null,
    customEndMoment: customEndMoment.isValid() ? customEndMoment : null,
  };
};

const checkAllBrandsSynced = ({ brandFetchStatus, customStartMoment, customEndMoment }) => {
  return brandFetchStatus.every((brand) => {
    const customPeriodData = brand?.custom_period_data_update;

    if (!customPeriodData || !customPeriodData.period) {
      return false;
    }

    const { periodStartMoment, periodEndMoment } = parsePeriod(customPeriodData.period);

    return (
      periodStartMoment &&
      periodEndMoment &&
      periodStartMoment.isSame(customStartMoment, 'day') &&
      periodEndMoment.isSame(customEndMoment, 'day')
    );
  });
};

const parsePeriod = (period) => {
  const [start, end] = period.split(' - ');

  return {
    periodStartMoment: moment(start, 'YYYY-MM-DD', true),
    periodEndMoment: moment(end, 'YYYY-MM-DD', true),
  };
};
